var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-manual-edit" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit()
            },
          },
        },
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("manual.title")))]),
          _c("fieldset", [
            _c("div", { staticClass: "item" }, [
              _c("label", { attrs: { for: "l" } }, [
                _vm._v(_vm._s(_vm.$t("manual.nr_cat"))),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.nr_cat,
                    expression: "nr_cat",
                  },
                ],
                attrs: { id: "l", type: "text", required: "" },
                domProps: { value: _vm.nr_cat },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.nr_cat = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("label", { attrs: { for: "p" } }, [
                _vm._v(_vm._s(_vm.$t("manual.qualifier"))),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.qualification_id,
                    expression: "qualification_id",
                  },
                ],
                attrs: { id: "p", type: "text", required: "" },
                domProps: { value: _vm.qualification_id },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.qualification_id = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("label", { attrs: { for: "p" } }, [
                _vm._v(_vm._s(_vm.$t("manual.rank"))),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.classement,
                    expression: "classement",
                  },
                ],
                attrs: { id: "p", type: "text", required: "" },
                domProps: { value: _vm.classement },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.classement = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("label", { attrs: { for: "p" } }, [
                _vm._v(_vm._s(_vm.$t("manual.reward"))),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.recompense,
                    expression: "recompense",
                  },
                ],
                attrs: { id: "p", type: "text", required: "" },
                domProps: { value: _vm.recompense },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.recompense = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("label", { attrs: { for: "p" } }, [
                _vm._v(_vm._s(_vm.$t("manual.judge"))),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.juge,
                    expression: "juge",
                  },
                ],
                attrs: { id: "p", type: "text", required: "" },
                domProps: { value: _vm.juge },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.juge = $event.target.value
                  },
                },
              }),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("label", { attrs: { for: "p" } }, [
                _vm._v(_vm._s(_vm.$t("manual.comment"))),
              ]),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.comment,
                    expression: "comment",
                  },
                ],
                attrs: { placeholder: _vm.$t("manual.comment_placeholder") },
                domProps: { value: _vm.comment },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.comment = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "actions" }, [
            _c(
              "button",
              { staticClass: "action cancel", on: { click: _vm.onReset } },
              [_vm._v(_vm._s(_vm.$t("global.reset")))]
            ),
            _c(
              "button",
              {
                staticClass: "action submit",
                attrs: { disabled: !_vm.isFilled },
                on: { click: _vm.onSubmit },
              },
              [_vm._v(_vm._s(_vm.$t("global.submit")))]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }